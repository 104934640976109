"use client";
import { type NavigationLinkGroups } from "@/features/navigation/navigation.type";
import { Contact, LayoutDashboard } from "lucide-react";
// import { LayoutDashboard, User2 } from "lucide-react";

export const DASHBOARD_LINKS: NavigationLinkGroups[] = [
  {
    links: [
      {
        title: "Dashboard",
        icon: <LayoutDashboard />,
        url: "/dashboard",
      },
      {
        title: "Leads",
        icon: <Contact />,
        url: "/dashboard/leads",
      },
    ],
  },
  // {
  //   title: "Other",
  //   links: [
  //     {
  //       title: "Users",
  //       icon: <User2 />,
  //       url: "/users",
  //     },
  //   ],
  // },
];
